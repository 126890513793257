<template>
  <v-dialog v-model="showExcludedModal" max-width="600px" @click:outside="closeExcluidosModal">
    <v-card>
      <v-card-title>
        <span class="headline">Planos Excluídos</span>
      </v-card-title>
      <v-card-text style="max-height: 400px; overflow-y: auto">
        <div v-if="planosExcluidos.length">
          <div v-for="(plano, index) in planosExcluidos" :key="plano.id">
            <v-btn small class="mb-4" color="primary" disabled>
              <v-icon end>delete</v-icon>
              Excluído em: {{ formatarData(plano.deleted_at) }}
            </v-btn>
            <br />
            <label>Professor(a)</label>
            <v-input>{{ plano.gestao_de_aula.professor.nome }}</v-input>

            <label>Turma</label>
            <v-input>{{ plano.gestao_de_aula.turma.descricao }}</v-input>

            <label>Bimestre</label>
            <v-input>{{ plano.bimestre.descricao }}</v-input>

            <label>Disciplina</label>
            <v-input>{{ plano.gestao_de_aula.disciplina.descricao }}</v-input>
            <h4><label>Informações</label></h4>
            <br />

            <div class="ml-5">
              <label>Tema</label>
              <v-input>{{ plano.tematica }}</v-input>

              <label>Objeto de Conhecimento</label>
              <v-input>{{ plano.objeto_conhecimento }}</v-input>

              <label>Habilidades</label>
              <v-input>{{ plano.habilidades }}</v-input>

              <label>Metodologia</label>
              <v-input>{{ plano.metodologia }}</v-input>

              <label>Recursos Didáticos</label>
              <v-input>{{ plano.recursos_didaticos }}</v-input>

              <label>Avaliação</label>
              <v-input>{{ plano.avaliacao_aprendizagem }}</v-input>

              <label>Referências</label>
              <v-input>{{ plano.referencias }}</v-input>
            </div>

            <v-divider v-if="index < planosExcluidos.length - 1" class="my-4"></v-divider>
          </div>
        </div>
        <p v-else>Nenhum plano excluído encontrado.</p>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="closeExcluidosModal">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dateFormat } from "../../plugins/filters";

export default {
  name: "PlanosExcluidosModal",
  props: {
    showExcludedModal: {
      type: Boolean,
      required: true,
    },
    planosExcluidos: {
      type: Array,
      required: true,
    },
  },
  methods: {
    closeExcluidosModal() {
      this.$emit("close");
    },
    formatarData(date) {
      return dateFormat(date, "d/m/y");
    },
  },
};
</script>
