<template>
  <v-dialog v-model="showHistoricModal" max-width="600px" @click:outside="closeHistoricoModal">
    <v-card>
      <v-card-title>
        <span class="headline">Histórico de Planos</span>
      </v-card-title>
      <v-card-text style="max-height: 400px; overflow-y: auto">
        <div v-if="planosHistorico.length">
          <div v-for="(plano, index) in planosHistorico" :key="plano.id">
            <v-btn small class="mb-4" color="primary" disabled>
              <v-icon end>delete</v-icon>
              Excluído em: {{ formatarData(plano.deleted_at) }}
            </v-btn>

            <div class="ml-5">
              <label>Tema</label>
              <v-input>{{ plano.tematica }}</v-input>

              <label>Objeto de Conhecimento</label>
              <v-input>{{ plano.objeto_conhecimento }}</v-input>

              <label>Habilidades</label>
              <v-input>{{ plano.habilidades }}</v-input>

              <label>Metodologia</label>
              <v-input>{{ plano.metodologia }}</v-input>

              <label>Recursos Didáticos</label>
              <v-input>{{ plano.recursos_didaticos }}</v-input>

              <label>Avaliação</label>
              <v-input>{{ plano.avaliacao_aprendizagem }}</v-input>

              <label>Referências</label>
              <v-input>{{ plano.referencias }}</v-input>
            </div>

            <v-divider v-if="index < planosHistorico.length - 1" class="my-4"></v-divider>
          </div>
        </div>

        <p v-else>Nenhum plano excluído encontrado.</p>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="closeHistoricoModal">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dateFormat } from "../../plugins/filters";

export default {
  name: "HistoricoPlanosModal",
  props: {
    showHistoricModal: {
      type: Boolean,
      required: true,
    },
    planosHistorico: {
      type: Array,
      required: true,
    },
  },
  methods: {
    closeHistoricoModal() {
      this.$emit("close");
    },
    formatarData(date) {
      return dateFormat(date, "d/m/y");
    },
  },
};
</script>
