<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.push({ name: 'home' })">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Planos
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-form>
          <v-card>
            <div class="text-start py-2 px-4 text-button">Pesquisa avançada</div>

            <v-divider class="theme--light"></v-divider>
            <v-row class="px-4 mt-4">
              <v-col v-if="permitirRolesParaGestores() >= 0">
                <v-text-field
                  v-model="professor"
                  label="NOME ou CPF Professor"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-space-between mb-6 px-4">
              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                  :items="disciplinas"
                  :return-object="false"
                  v-model="disciplina"
                  label="Escolha uma disciplina"
                  item-text="descricao"
                  item-value="descricao"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                  :items="turmas"
                  :return-object="false"
                  v-model="turma"
                  label="Escolha uma turma"
                  item-text="descricao"
                  item-value="descricao"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="4" s>
                <v-autocomplete
                  :items="bimestres"
                  :return-object="false"
                  v-model="bimestre"
                  label="Escolha uma bimestre"
                  item-text="descricao"
                  item-value="id"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4" s>
                <v-autocomplete
                  :items="statusList"
                  :return-object="false"
                  v-model="status"
                  label="Escolha uma Status"
                  item-text="descricao"
                  item-value="id"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" md="12" class="mb-5">
                <v-btn color="primary" block @click="() => limparCampos()">Limpar filtros</v-btn>
              </v-col>
              <v-col cols="12" sm="8" md="6" lg="4" xl="2" class="ml-auto">
                <v-btn
                  color="red lighten-1"
                  block
                  @click="openExcluidosModal()"
                  class="white--text"
                >
                  <span>Planos excluídos</span>
                  <v-icon class="ml-2">auto_delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>

      <v-col>
        <v-data-table
          :loading="carregando"
          :headers="table.headers"
          :items="planos"
          :items-per-page="15"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr color="primary" v-for="plano of items" :key="plano.id">
                <td>{{ plano.id ? plano.id : "- - -" }}</td>
                <td>
                  {{ plano.gestao_de_aula.professor.nome }}
                </td>
                <td>{{ plano.gestao_de_aula.turma.descricao }}</td>
                <td>
                  <!-- {{ plano.status ? plano.status : "----" }} -->
                  <e-situacao-plano
                    :plano="plano"
                    @update="(e) => [carregarDadosAoAtualizarStatus(e)]"
                  />
                </td>
                <td>
                  {{ plano.bimestre ? plano.bimestre.descricao : "1º Bimestre" }}
                </td>
                <td>{{ plano.gestao_de_aula.disciplina?.descricao }}</td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="
                          () =>
                            $router.push({
                              name: 'gestoesDeAulas.planosVisualizar',
                              params: { id: plano.id },
                            })
                        "
                        x-small
                        class="ml-1"
                        v-bind="attrs"
                        color="orange"
                        v-on="on"
                      >
                        <v-icon small>mdi-note-text-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="() => visualizarPdf(plano.id)"
                        x-small
                        class="ml-1"
                        v-bind="attrs"
                        color="orange"
                        v-on="on"
                      >
                        <v-icon small>mdi-note </v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar Pdf</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="plano.status?.descricao.includes('Plano inválido')">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="softDelete(plano)"
                        x-small
                        class="ml-1"
                        v-bind="attrs"
                        color="red"
                        v-on="on"
                        right
                      >
                        <v-icon small color="white">delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Limpar Campos</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="openHistoricoModal(plano)"
                        x-small
                        class="ml-1"
                        v-bind="attrs"
                        color="gray"
                        v-on="on"
                        right
                      >
                        <v-icon small color="black">history</v-icon>
                      </v-btn>
                    </template>
                    <span>Histórico de planos excluídos</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="carregarPlanos"
          :search="search"
        >
        </e-paginate>
      </v-col>
    </v-row>

    <ModalCarteira
      :dialog="showPdfModal"
      :pdf="pdf"
      v-on:update="carregarDadosAoAtualizarStatus"
      @close="closePdfModal"
    />

    <HistoricoPlanosModal
      :showHistoricModal="showHistoricModal"
      :planosHistorico="planosHistorico"
      @close="closeHistoricoModal"
    />

    <PlanosExcluidosModal
      :showExcludedModal="showExcludedModal"
      :planosExcluidos="planosExcluidos"
      @close="closeExcluidosModal"
    />
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import ModalCarteira from "@/components/Matriculas/EModalCarteira.vue";
import { mapGetters } from "vuex";
import HistoricoPlanosModal from "@/components/Planos/HistoricoPlanosModal.vue";
import PlanosExcluidosModal from "@/components/Planos/PlanosExcluidosModal.vue";

export default {
  components: {
    ModalCarteira,
    HistoricoPlanosModal,
    PlanosExcluidosModal,
  },
  watch: {
    async professor(value) {
      value = this.formatarCPf(value);
      this.professor = value;
      await this.carregarPlanos();
    },
    async turma(value) {
      this.turma = value;
      await this.carregarPlanos();
    },
    async disciplina(value) {
      this.disciplina = value;
      await this.carregarPlanos();
    },
    async mes(value) {
      this.mes = value;
      await this.carregarPlanos();
    },
    async bimestre(value) {
      this.bimestre = value;
      await this.carregarPlanos();
    },
    async status(value) {
      this.status = value;
      await this.carregarPlanos();
    },
  },
  data() {
    return {
      carregando: false,
      dialog: false,
      pdf: null,
      disciplinas: [],
      bimestres: [],
      turmas: [],
      planos: [],
      statusList: [],
      descricaoDosBimestres: {
        1: "2° Bimestre",
        2: "3° Bimestre",
        3: "4° Bimestre",
      },
      professor: "",
      turma: "",
      disciplina: "",
      bimestre: "",
      status: "",
      dataTableFilter,
      search: "",
      pageNumber: 1,
      paginate: {
        current_page: 1,
        last_page: 0,
      },
      planosHistorico: [],
      showHistoricModal: false,
      planosExcluidos: [],
      showExcludedModal: false,
      showPdfModal: false,
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Professor", value: "gestao_de_aula.professor.nome" },
          { text: "Turma", value: "gestao_de_aula.turma.descricao" },
          { text: "Status", value: "status.descricao" },
          { text: "Bimestre", value: "bimestre" },
          { text: "Disciplina", value: "gestao_de_aula.disciplina.descricao" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  async mounted() {
    this.carregarDados();
    this.permitirRolesParaGestores();
  },
  methods: {
    async carregarDados() {
      this.carregarPlanos(this.pageNumber);
      this.listarDisciplinas();
      this.listarTurmas();
      this.listarBimestres();
      this.listarStatus();
    },
    permitirRolesParaGestores() {
      try {
        // const userLocal = this.user?.role ? this.user.role.name : [];
        // return this.$constants.rolesParaGestores.findIndex((elem) => {
        //   return elem.name === userLocal;
        // });

        const userLocal = this.user.roles ? this.user.roleName : [];
        return this.$constants.rolesParaGestores.findIndex((elem) => {
          return elem.name === userLocal;
        });
      } catch (error) {
        this.$handleError(error);
      }
      return false;
    },
    async listarDisciplinas() {
      try {
        const { data } = await this.$services.planosService.disciplinas();
        this.disciplinas = data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async listarStatus() {
      try {
        const { data } = await this.$services.planosService.status();
        this.statusList = data;
        console.log(this.data);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async listarBimestres() {
      try {
        const { data } = await this.$services.planosService.bimestres();
        this.bimestres = data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async listarTurmas() {
      const franquiasPermitidas = this.user.franquias_permitidas;
      if (franquiasPermitidas == null) {
        try {
          const { data } = await this.$services.planosService.turmas();
          const result = data.map((t) => {
            const local = { ...t };
            return (local.descricao = `${t.descricao} (${t.franquia.descricao})`);
          });
          this.turmas = result;
        } catch (error) {
          this.$handleError(error);
        }
      } else {
        try {
          const { data } = await this.$services.planosService.turmas();
          // Filtrar turmas conforme as franquias permitidas
          const franquiasPermitidas = this.user.franquias_permitidas; // Acessando as franquias permitidas do usuário
          const filteredData = data.filter((t) =>
            franquiasPermitidas.includes(Number(t.franquia.id))
          ); // Verifica se o ID da franquia está no array
          const result = filteredData.map((t) => {
            const local = { ...t };
            local.descricao = `${t.descricao} (${t.franquia.descricao})`;
            return local;
          });
          this.turmas = result;
        } catch (error) {
          this.$handleError(error);
        }
      }
    },
    async carregarPlanos(numeroDaPagina = null) {
      try {
        this.dialog = false;
        this.carregando = true;
        const pagina = numeroDaPagina || this.pageNumber;
        const payload = await this.$services.planosService.index(
          pagina,
          this.professor,
          this.turma,
          this.disciplina,
          this.bimestre,
          this.status
        );
        this.planos = payload.data;
        this.paginate = payload;
        this.carregando = false;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async carregarDadosAoAtualizarStatus() {
      try {
        this.carregarPlanos(this.paginate.current_page);
      } catch (error) {
        this.$handleError(error);
      }
    },
    limparCampos() {
      this.professor = "";
      this.turma = "";
      this.disciplina = "";
      this.mes = "";
      this.bimestre = "";
      this.status = "";
    },
    formatarCPf(value) {
      value = value.replace("-", "");
      value = value.replace(".", "");
      return value;
    },
    async visualizarPdf(planoId) {
      this.pdf = await this.$services.planosService.pdf(planoId);
      this.showPdfModal = true;
    },
    async softDelete(plano) {
      this.$modals.danger({
        title: "Atenção!",
        message: "Tem certeza que deseja apagar este plano bimestral?",
        confirmationCode: plano.id,
        confirmationMessage: `Por favor, digite <strong>${plano.id}</strong> para confirmar a exclusão.`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando o Plano Bimestral");
              try {
                const response = await this.$services.planosService.softDelete(plano.id);
                if (response) {
                  this.$toast.success("Plano bimestral deletado com sucesso.");
                  this.carregarPlanos(this.paginate.current_page);
                }
              } catch (error) {
                this.$handleError(error);
                this.$toast.error("Erro ao tentar apagar o plano.");
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },
    async openHistoricoModal(plano) {
      try {
        const historico = await this.$services.planosService.getHistoricoPlanos(
          plano.gestao_de_aula.id,
          plano.gestao_de_aula.instrutor_id,
          plano.gestao_de_aula.turma.id,
          plano.disciplina_id,
          plano.bimestre.id
        );
        this.planosHistorico = historico;
        this.showHistoricModal = !this.showHistoricModal;
      } catch (error) {
        console.error("Erro ao carregar histórico de planos", error);
      }
    },
    closeHistoricoModal() {
      this.showHistoricModal = !this.showHistoricModal;
    },
    async openExcluidosModal() {
      try {
        const excluidos = await this.$services.planosService.getPlanosExcluidos();
        this.planosExcluidos = excluidos;
        this.showExcludedModal = !this.showExcludedModal;
      } catch (error) {
        console.error("Erro ao carregar histórico de planos", error);
      }
    },
    closeExcluidosModal() {
      this.showExcludedModal = !this.showExcludedModal;
    },
    closePdfModal() {
      this.showPdfModal = false;
    },
  },
};
</script>
<style></style>
