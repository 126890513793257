<template>
  <v-chip :label="true" :color="getColor(aula.tipo_de_aula)" class="text-center" x-small>
    {{ aula.tipo_de_aula || "- - -" }}
  </v-chip>
</template>

<script>
export default {
  props: {
    aula: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getColor(tipoDeAula) {
      switch (tipoDeAula) {
        case "Aula Remota":
          return "info";
        case "Aula Normal":
          return "green";
        case "Reposição":
          return "orange";
        case "Aula Extra":
          return "purple";
        case "Substituição":
          return "red";
        case "Aula Antecipada":
          return "yellow";
        case "Atividade Extra-classe":
          return "teal";
        case "Recuperação":
          return "brown";
        default:
          return "grey";
      }
    },
  },
};
</script>
